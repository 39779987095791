.reservation-info-card {
  .float-right {
    margin-left: 1em;
    margin-top: 10px;
  }

  .infocard-title-section {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1em;
  }

  .infocard-title {
    flex-grow: 1;
  }

  .infocard-subtitle {
    .material-symbols-outlined {
      font-size: 18px; 
    }
  }
}