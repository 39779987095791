@import '../style/variables.scss';

.main-header {
  top: 0;
  z-index: 10;
  position: sticky;
  display: flex;
  align-items: center;

  background-color: rgba(77, 63, 120, 0.9);
  color: white;
  padding: 0.5em 1em;
  border-bottom: 1px solid $mainBrand;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

  .main-header-logo {
    img {
      width: 32px;
      height: 32px;
    }

    .material-icons {
      font-size: 32px;
      padding-top: 2px;
    }

    margin-right: 0.75em;
  }

  h1 {
    font-size: 1.2em;
    margin-bottom: 0;
    line-height: normal;
  }

  .dates {
    font-size: 0.8em;
  }

  .main-header-info {
    flex: 1;
  }

  .main-header-userinfo {
    .btn {
      padding: 0.25em 0.75em;
    }
  }
}

.hero {
  position: relative;
  display: flex;
  flex-shrink: 0;
  border-bottom: 1px solid $mainBrand;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  overflow: hidden;

  .hero-content {
    padding: 2em 1em;
    flex: 1;
    justify-content: center;
  }

  .hero-image {
    position: absolute;
    width: 100vw;
    min-height: 100%;

    z-index: -1;
  }

  .hero-filler {
    margin-bottom: 5em;
  }
}

.hero-content .card, .modal .modal-content {
  background-color: transparentize($darkShade, 0.05);
  border-color: $mainBrand;
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 0.5em;

  h2 {
    font-size: 1.5em;
    margin-top: -0.45em;
    margin-bottom: 0.25em;
  }

  p {
    margin-bottom: 0;
  }
}

.maintenance-banner {
  text-align: center;
  padding: 0.5em 1em;
  border-bottom: 1px solid $mainBrand;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  background-color: #a00606;
}