.userinfo__loggedin {
  color: white !important;
  text-decoration: none !important;

  &:focus {
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .badge {
    margin-right: 10px;
  }
}