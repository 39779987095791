.hotel-room-availability {
  flex: 1;
  min-width: 65px;
  text-align: center;
  overflow: hidden;
  border: 1px solid;
  border-radius: 0.5em;

  .hotel-room-availability-core-night {
    position: absolute;
    padding: 0.25em;
    color: gold;

    .material-icons {
      cursor: default;
    }
  }

  .hotel-room-availability-day {
    margin-top: 0.25em;
  }

  .hotel-room-availability-date {
    padding-bottom: 0.25em;
    border-bottom: 1px solid;
  }

  .hotel-room-availability-main {
    height: 100%;
  }

  .hotel-room-availability-info {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    color: black;
    padding: 0.25em 0;
    height: 50%;
  }

  .hotel-room-availability-count, .hotel-room-availability-price {
    flex: 1;
  }

  .hotel-room-availability-divider {
    border-right: 1px solid;
  }

  &.sold-out {
    .hotel-room-availability-info {
      background-color: #0D0D0D;
      color: #F27878;
    }
  }
}

// Mobile
@media (max-width: 768px) {
  .hotel-room-availability {
    min-width: 50px;
  }

  .hotel-room-availability-info {
    flex-direction: column !important;
  }
}