@import "variables.scss";

@import "~bootstrap/scss/bootstrap";

@import "placeholder.scss";

html, body, #app, #app > div {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

body {
  background-color: #282432;
  color: white;
  font-size: 14px;
}

footer {
  background-color: rgba(77, 63, 120, 0.9);
  border-top: 1px solid $mainBrand;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

  div {
    display: flex;
    align-items: center;
  }

  img {
    height: 33px;
    width: auto;
    cursor: pointer;
    margin-right: 1em;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

h1 {
  font-size: 36px;
  line-height: 42px;
  font-weight: 600;
}

h2 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 600;
}

h3, h4, h5, h6 {
  font-weight: 400;
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

h4 {
  font-size: 18px;
  line-height: 20px;
}

h5 {
  font-size: 16px;
  line-height: 22px;
}

h6 {
  font-size: 14px;
  line-height: 20px;
}

hr {
  border-color: $mainBrand;
}

button {
  &.button-flex {
    display: flex !important;
  }

  .material-icons {
    margin-left: -0.15em;
    margin-right: 0.25em;
  }
}

.material-icons.xsmall {
  font-size: 12px;
}

.material-icons.small {
  font-size: 18px;
}

.material-icons.medium {
  font-size: 32px;
}

.material-icons.large {
  font-size: 64px;
}

.text-with-icon {
  display: inline-flex;
  align-items: center;

  .material-icons {
    margin: 0 0.15em;
  }
}

.modal .modal-content .modal-header, .modal .modal-content .modal-footer {
  border-color: $mainBrand;
}

.main-content {
  padding: 1em 2em;
}

.card {
  background-color: #3b344d;
}

.flex-expand {
  flex: 1;
}

.align-center {
  align-items: center;
}

table {
  color: white !important;
}

.btn {
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.badge-list, .icon-list, .action-list {
  display: flex;
  // add gap between flex items
  gap: 0.5em;
}

.badge {
  display: inline-flex;
  align-items: center;
  cursor: default;

  .material-icons {
    cursor: default;
    width: 15px;
    height: 15px;
    margin-right: 0.25em;
    font-size: 1.25em;
  }
}

.inline-help {
  display: inline-flex;
  position: relative;
  margin-left: 0;
  // top: 3px;
}

.card-item-header {
  display: flex;

  > div:first-child {
    flex-grow: 1;
  }
}

.nav-item {
  + .nav-item {
    margin-left: 5px;
  }
}

.nav-link, .nav-link:hover {
  color: white !important;
  cursor: pointer;

  border-bottom: 0;

  &.active {
    background-color: rgba(77, 63, 120, 0.9) !important;
    border-color: $mainBrand !important;
  }
}

.nav.nav-tabs {
  border-color: $mainBrand;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  margin-bottom: 0.75em;
}

.small-text {
  font-size: 90%;
}

.modal .close {
  color: white;
}

// on mobile, make .hotel a column
@media (max-width: 768px) {
  .hotel {
    flex-direction: column;
  }
}
