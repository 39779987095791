.system-error-preparing-convention {
  display: flex;
  flex-direction: row !important;

  align-items: center;
  justify-content: center;
  gap: 3em;

  img {
    height: 48px;
    width: 48px;
  }

  a, a:visited, a:hover, a:active {
    color: #fff;
  }

  .system-error-logo {
    display: flex;
    gap: 1em;

    flex-direction: column;
    align-items: center;
  }
}
