.splash-screen {
  display: flex;
  width: 100vw;
  height: 100vh;

  align-items: center;
  justify-content: center;

  .splash-screen__card {
    padding: 0.5em 2em;
    text-align: center;
  }

  .splash-screen__logo {
    margin-top: 2em;

    img {
      height: 35px;
    }
  }

  .splash-screen__quote {
    font-style: italic;
    font-size: 0.9em;
  }
}