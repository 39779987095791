.empty-state-body {
  display: flex;
  align-items: center;

  .material-icons {
    margin-right: 15px;
    margin-top: 4px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}