@import "../../style/variables.scss";

.collapse-element {
  .warning-banner {
    padding: 0.5rem 1.25rem;
    border-bottom: 1px solid #7d67b2;
    background-color: rgba(255, 228, 79, 0.65);
    color: black;
  }

  .collapse-header {
    > .badge {
      margin-left: 0.5rem;
    }
  }

  .collapse-item {
    border-bottom: 1px solid $mainBrand;
    padding-top: 0.5rem;
    padding-bottom: 1.15rem;
    margin-top: 0.5rem;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &:first-child {
      margin-top: -0.75em;
    }
  }
}