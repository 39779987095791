@import "../style/variables.scss";

.hotel-list .hotel:last-child {
  margin-bottom: 1em;
}

.hotel-search-alert-element {
  display: flex;
  flex-direction: row;
  padding: 1em 2em;
  gap: 2em;
  background: rgba(77, 63, 120, 0.9);
  align-items: center;

  border-bottom: 1px solid $mainBrand;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);

  .gap {
    flex-grow: 1;
  }

  &.hotel-search-alert-element--success {
    background: rgba(70, 97, 64, 0.9);
    border-bottom: 1px solid #4e9a4b;
  }
}

@media (max-width: 768px) {
  .hotel-search-alert-element {
    flex-direction: column;
    gap: 0.35em;
    align-items: flex-start;
  }
}

@media (min-width: 768px) {
  .hotel-search-alert-element {
    .btn-block {
      width: initial;
      display: inherit;
    }
  }
}