@import '../style/variables.scss';

.hotel-room {
  display: flex;
  gap: 1em;

  .hotel-room-info {
    flex: 0.25;
  }

  .hotel-room-name {
    font-weight: 600;
  }

  .hotel-room-details {
    flex: 0.75;
  }

  .hotel-room-price {
    display: flex;
  }

  .hotel-room-metadata {
    margin-top: 1em;

    display: inline-flex;
    flex-direction: column;
    line-height: 20px;

    .material-icons {
      width: 16px;
      height: 16px;
      font-size: 16px;
    }
    
    .hotel-room-badges {
      margin-top: 0.75em;
    }

    .text-with-icon {
      margin-left: -0.2em;
    }
  }

  .hotel-room-description {
    margin-bottom: 1em;

    > p:last-child {
      margin-bottom: 0;
    }
  }

  .hotel-room-availability-list {
    display: flex;
    gap: 0.5em;
  }

  + .hotel-room {
    border-top: 1px solid $mainBrand;
    margin-top: 1em;
    padding-top: 1em;
  }
}

// on mobile
@media (max-width: 768px) {
  .hotel-room {
    flex-direction: column;

    .hotel-room-availability-list {
      overflow-x: scroll;
    }
  }
}