.increment-picker {
  display: flex;
  text-align: center;
  margin-top: 3px;

  .increment-picker__button {
    cursor: pointer;
    width: 30px;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &.increment-picker__button-disabled {
      cursor: not-allowed;
      opacity: 0.35;
    }
  }

  .increment-picker__value {
    flex: 1;
    margin-top: 1px;
  }
}