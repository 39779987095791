.hotel-search {
  display: flex;
  padding: 10px 13px;
  flex-direction: row;
  flex-flow: wrap;
  gap: 1.5em;

  .hotel-search-element {
    display: flex;
    flex-direction: row;

    &.hotel-search-button {
      flex-direction: column;
    }

    .hotel-search-element-icon {
      flex: 1;

      .material-icons {
        font-size: 1.25em;
        padding-right: 0.25em;
      }
    }

    .hotel-search-element-inner {
      display: flex;
      flex-direction: column;
      gap: 0.25em;
      margin-left: -1.4em;
    }

    .hotel-search-element-label {
      font-size: 12px;
      font-weight: bold;
      margin-left: 1.65em;
    }

    &.hotel-search-button {
      margin-left: 0.5em;
      justify-content: center;
    }

    input[type="number"] {
      width: 115px;
    }
  }

  .form-control {
    font-size: 12px;
    padding: 0.5em;
  }
}