@import "../style/variables.scss";

.hotel {
  cursor: pointer;
  display: flex;
  transition: 0.5s ease-out;
  transition-property: background-color, box-shadow;

  h4 {
    font-weight: 600;
  }

  .hotel-header {
    display: flex;

    .hotel-info {
      flex: 1;
      margin-right: 1.5em;
    }

    .hotel-lowest-price {
      .hotel-lowest-price-text {
        font-size: 12px;
      }

      .hotel-lowest-price-amount {
        h4 {
          font-weight: 600;
        }

        small {
          font-size: 11px;
        }
      }
    }
  }

  .hotel-badges {
    margin-top: 0.5em;
  }

  .hotel-description {
    margin-top: 1.5em;
  }

  &.hotel-select-all {
    h4 {
      font-size: 16px;
    }

    .card-body {
      padding: 0.70em 1.25em;
    }
  }

  &:hover, &.hotel-selected {
    background-color: #63518f;

    border-color: $mainBrand;
    //box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.5);

    // dropshadow
    box-shadow: 3px 3px 2px transparentize($color: #1e1731, $amount: 0.2);
  }

  & + & {
    margin-top: 1em;
  }
}