@import "../../style/variables.scss";

.card .infocard {
  background-color: $darkShadeContrast;
  border-color: $mainBrand;
}

.infocard {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  .infocard-edit-icon {
    cursor: pointer;
  }

  + .infocard {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px;
  }

  &:last-child {
    border-bottom-left-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important;
  }
}