@import "../../style/variables.scss";

.card-body .metricblock {
  border-color: $mainBrand !important;
  background-color: $darkShadeContrast !important;
}

.metricblock {
  margin-bottom: 1em;

  .card-body {
    display: flex;
    padding: 1em;
  }

  .metricblock-icon {
    margin-right: 1em;

    .material-icons {
      font-size: 32px;
    }
  }

  .metricblock-info {
    display: flex;
    flex-direction: column;

    // .metricblock-title .inline-icon {
    //   position: relative;
    //   top: 3px;
    // }

    .metricblock-body {
      display: flex;
      flex-direction: row;
    }

    .metricblock-value {
      font-size: 24px;
      font-weight: bold;
    }

    .metricblock-statusIcon {
      margin-right: 0.5em;
      align-self: end;
    }
  }
}