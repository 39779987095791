@import "../../style/variables.scss";

.sidebar-header {
  font-size: 12px;
  margin-bottom: 0.5em;
  color: #c6c6c6;
}

.sidebar-item {
  cursor: pointer;
  padding: 5px 0;

  + .sidebar-item {
    margin-top: 0.1em;
  }

  &.sidebar-item-selected, &:hover {
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #766a9d;
    background-color: #5438b7;
  }

  .sidebar-item-content {
    display: flex;

    > div {
      display: flex;
      align-self: center;
    }
  }

  .sidebar-item-content-icon {
    margin-right: 0.5em;
  }
}

.sidebar-divider {
  margin: 0.5em 0;
  border-bottom: 2px solid $darkShade;
}