.criteria-list {
  list-style-type: none;
  padding-left: 0.5em;
  margin-top: 0.5em;

  .criteria-item {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex: 1;
    }

    .criteria-error-helptext {
      margin-left: 1em;
      font-size: 0.8em;
      margin-bottom: 0.5em;
    }
    
    .material-icons {
      margin-right: 0.25em;
    }
  }

  .criteria-met .material-icons {
    color: #29b671;
  }

  .criteria-unmet {
    font-weight: 600;

    .material-icons {
      color: #e74c3c;
    }
  }
}